import React from "react";
import Image from "next/image";

const FavoritePreview = ({ favorite }) => {
  return (
    <div className={`rounded article-container shadow-lg flex flex-col overflow-hidden cursor-pointer relative w-full`}>
      <div className="relative">
        <div className="h-full overflow-hidden">
          <div className="article-image aspect-16/9 relative">
            <Image
              src={favorite.picture_url}
              alt={favorite.title}
              fill
              sizes="(min-width: 640px) 50vw, 100vw"
              style={{objectFit: "contain"}}
            />
          </div>
        </div>
      </div>
      <div className="p-6 flex flex-col justify-start h-full">
        <div className="flex-1">
          <p className="font-normal text-sm pb-1">
            <span className="text-red">{favorite.score} points</span> (${favorite.price})
          </p>
          <h2 className="transition-all text-xl font-bold pb-2">
            {favorite.title}
          </h2>
          <p className="text-base font-light">
            {favorite.caption}
          </p>
        </div>
      </div>
    </div>
  )
}

export default FavoritePreview;
