import React from "react";
import ReadMore from "../Buttons/ReadMore";
import { useRouter } from 'next/router';
import Loading from '../Loading';
import { format } from 'date-fns';
import LinesEllipsis from 'react-lines-ellipsis';
import { removeHTML } from "../../../utils/helper";
import Link from "next/link";
import { defaultImage } from "../../../utils/image";
import { convertDateToLocalDate } from "../../../utils/date";

function ArticleHero({ article }) {
  const router = useRouter();
  const [imageUrl, setImageUrl] = React.useState<string>(
    article?.image_url
      ? article.image_url
      : defaultImage
  );

  const handleNavigateToArticle = (e) => {
    e.preventDefault();
    router.push(`/articles/${article.slug}`);
  };

  React.useEffect(() => {
    const img = new Image();
    img.src = imageUrl
    img.onload = () => setImageUrl(imageUrl);
    img.onerror = () => setImageUrl(defaultImage);
  }, [imageUrl]);

  if(!article) {
    return (
      <div className="flex items-center rounded shadow-lg w-full h-72 mb-8">
        <Loading isLoading={true}/>
      </div>
    )
  }
  return (
    <div className={`rounded article-container transition shadow-lg overflow-hidden lg:flex cursor-pointer mb-8`} onClick={(e) => handleNavigateToArticle(e)}>
      <div className="w-full h-full lg:h-auto lg:w-2/3 overflow-hidden">
        <div
          className="article-image h-full aspect-16/9 bg-cover bg-center hover"
          style={{
            backgroundImage: `url("${imageUrl}")`
          }}
        >
        </div>
      </div>
      <div className="w-full lg:w-1/3 p-6 flex flex-col">
        <div className="h-full">
          <p className="font-normal text-sm opacity-40 pb-2">
            {article && `${article.slug === 'josh-raynolds-in-memoriam-1962-2023-mar-2023' ? "March 24, 2023" : format(convertDateToLocalDate(article.date), "MMMM d, yyyy")} - ${article.author}`}
          </p>
            <h2 className="transition-all text-2xl lg:text-3xl font-bold pb-2">
              <Link href={`/articles/${article.slug}`}>{article && article.extracted_title}</Link>
            </h2>

            <LinesEllipsis
              className="text-base font-light"
              maxLine={10}
              ellipsis="..."
              text={removeHTML(article.preview)}
              trimRight
            />
        </div>
        <ReadMore
          className="pt-12"
          link={`/articles/${article.slug}`}
        />
      </div>
    </div>
  )
}

export default ArticleHero;
