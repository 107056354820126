import React from "react";
import Layout from "../components/layout/Layout/Layout";
import Header from "../components/layout/Header/Header";
import ArticleHero from "../components/global/Articles/Hero";
import LatestArticles from "../components/global/Articles/collections/LatestArticles";
import CellarFavorites from "../components/global/Articles/collections/CellarFavorites";
import VinousFavorites from "../components/global/Articles/collections/VinousFavorites";
import VinousTable from "../components/global/Articles/collections/VinousTable";
import HomepageAuthors from "../components/global/Authors/collections/HomepageAuthors";
import BaseWineSearchBar from "../components/global/SearchBar/BaseWineSearchBar";
import { GetStaticProps } from "next";
import {
  getLatestArticles,
  getVinousTableArticles,
  getCellarFavoritesArticles,
} from "../lib/articleRequests";
import { getHomepageNotices } from "../lib/bannerRequests";
import Banners from "../components/global/Banners/Banners";
import HomepageMultimedia from "../components/global/Multimedia/collections/HomepageMultimedia";
import { getVinousFavorites } from "../lib/wineRequests";
import { useRouter } from "next/router";
import { useToast } from "../context/toastContext";
import Head from "next/head";
import Loading from "../components/global/Loading";
import { useStripeSuccess } from "../hooks/useStripeSuccess";
import SearchBar from "../components/global/SearchBar/SearchBar";

const HomePage = ({
  latestArticles,
  vinousTableArticles,
  cellarFavoritesArticles,
  vinousFavorites,
  homepageNotices,
}) => {
  const router = useRouter();
  const toast = useToast(2000);
  const { signed_up } = router.query;
  const { isCompletingUser } = useStripeSuccess();

  if (signed_up === "") {
    toast("success", "Welcome. You have subscribed successfully.", null, () => {
      router.replace("/", undefined, { shallow: true });
    });
  }

  return (
    <Layout>
      <Head>
        <title>Antonio Galloni presents Vinous | Explore All Things Wine</title>
      </Head>
      <Header user={false} />
      {isCompletingUser ? (
        <Loading isLoading={isCompletingUser}></Loading>
      ) : (
        <>
          <SearchBar className="pb-2 md:pb-4" hideResults={true} autoComplete={true}/>
          <div className="xl:flex xl:space-x-4">
            <div className="w-full xl:w-5/6">
              <ArticleHero article={latestArticles[0]} />
              <LatestArticles latestArticles={latestArticles} />
              <VinousTable vinousTableArticles={vinousTableArticles} />
              <VinousFavorites vinousFavorites={vinousFavorites} />
              <CellarFavorites
                cellarFavoritesArticles={cellarFavoritesArticles}
              />
              <HomepageMultimedia />
              <HomepageAuthors />
            </div>
            <div className="w-full xl:w-1/6">
              <Banners banners={homepageNotices} />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export const getServerSideProps: GetStaticProps = async () => {
  const responseLatestArticles = await getLatestArticles();
  const responseVinousTable = await getVinousTableArticles();
  const responseCellarFavorites = await getCellarFavoritesArticles();
  const responseNotices = await getHomepageNotices();
  const responseVinousFavorites = await getVinousFavorites();

  return {
    props: {
      latestArticles: responseLatestArticles.data,
      vinousTableArticles: responseVinousTable.data,
      cellarFavoritesArticles: responseCellarFavorites.data,
      vinousFavorites: responseVinousFavorites.data.favorites,
      homepageNotices: responseNotices.data.banners,
    },
  };
};

export default HomePage;
