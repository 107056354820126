const images = [
  "https://images.vinous.com/article_assets/pictures/000/002/256/home_page/An_impressive_lineup_of_corks_of_twenty-year_old_wines_cover.jpg",
  "https://images.vinous.com/sidebar/sidebar_images/000/000/309/saint_emilion_bottles.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/191/home_page/SleepingLady.jpgcopy.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/251/home_page/Rocky_Point_Winter_Lo_Res.jpg_copy.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/248/home_page/Looking_out_toward_the_Frosca_and_Salvarenza_crus_of_Soave.jpg_copy.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/245/home_page/iona_line_up.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/242/home_page/Monviso(Cover).jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/241/home_page/2021_Central_Spain_Photo_Alonso_del_Yerro_3.jpg_copy.jpg",
  "https://images.vinous.com/article_assets/pictures/000/002/239/home_page/2018_Tuscany_Preview.png",
  "https://images.vinous.com/article_assets/pictures/000/002/238/home_page/N._1.jpg_-_Northern_Argentina_-_Fernando_Dupont.jpg_copy2.jpg"
]

export const articleImage = () => {
  return images[Math.floor(Math.random() * images.length)];
}

export const defaultImage = "/img/vinous_article_thumb_placeholder.png";
