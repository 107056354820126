import React from "react";
import SectionWrapper from "../../Section/SectionWrapper";
import AuthorPreview, { AuthorStatic } from "../Preview";

const HomepageAuthors = () => {
  const authors: AuthorStatic[] = [
    {
      name: "Antonio Galloni",
      url: "/img/authors/antonio.png"
    },
    {
      name: "Stephen Tanzer",
      url: "https://allgrapes.s3.amazonaws.com/uploads%2F1417385730830-ST+362.jpg"
    },
    {
      name: "Neal Martin",
      url: "/img/authors/neal.jpg"
    },
    {
      name: "Eric Guido",
      url: "https://s3.amazonaws.com/images.vinous.com/uploads%2F1726065606723-EG+Headshot+for+About+Page.jpg"
    },
    {
      name: "Billy Norris",
      url: "https://s3.amazonaws.com/images.vinous.com/uploads%2F1716308661176-BN+Shot_copyaboutpage.jpg"
    },
    {
      name: "Rebecca Gibb MW",
      url: "https://mcusercontent.com/1b0468fdd47cc4ecb9d11de24/images/3b8f8a93-cc28-4cab-8e95-fc3671f70a9a.jpg"
    },
    {
      name: "Joaquín Hidalgo",
      url: "https://s3.amazonaws.com/images.vinous.com/uploads%2F1579280531754-Hidalgo+vinous.jpg"
    },
    {
      name: "Alessandro Masnaghetti",
      url: "/img/authors/alessandro.png"
    },
    {
      name: "Angus Hughson",
      url: "/img/authors/angus_hughson_2.jpg"
    },
    {
      name: "Anne Krebiehl MW",
      url: "/img/authors/anne-krebiehl-mw.png"
    },
    {
      name: "Nicolas Greinacher",
      url: "/img/authors/nicolas.png"
    },
    {
      name: "Josh Raynolds (In Memoriam)",
      url: "/img/authors/josh-raynolds.png"
    },
  ];

  

  return (
    <SectionWrapper 
      title="Team"
      className="grid grid-col grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 pb-16"
    >
      {authors.map((author, idx) => {
        return <AuthorPreview key={idx} author={author}/>
      })}
    </SectionWrapper>
  )
}

export default HomepageAuthors;