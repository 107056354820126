import { useRouter } from "next/router";
import { useState } from "react";
import Link from "next/link";

const ReadMore = (props: { 
  className?: string,
  link: string, 
  label?: any 
}) => {
  const router = useRouter();
  const [hover, setHover] = useState(false);

  return (
    <div 
      className={`flex items-center ${ props.className } ${ hover && 'text-red'}`}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      onClick={() => { router.push(props.link) }}
    > 
      <div className="transition-all w-full block">
        <Link href={props.link}>
          { props.label ? props.label : "Read More" }
        </Link>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="transition-all opacity-20" fill="none" height="24" width="24" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </svg>
    </div>
  )
}

export default ReadMore;