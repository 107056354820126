import React, { useState } from "react"
import SectionWrapper from "../../Section/SectionWrapper"
import ArticlePreview from "../Preview"
import Loading from '../../Loading';

const CellarFavorites = ({ cellarFavoritesArticles }) => {
  const fetchArticleOrUndefined = (loc: number) => {
    if(cellarFavoritesArticles && cellarFavoritesArticles.length > loc) return cellarFavoritesArticles[loc];
    else return undefined;
  }

  if (!(cellarFavoritesArticles && cellarFavoritesArticles.length > 0)) {
    return (
      <SectionWrapper 
        title="Cellar Favorites"
      >
        <div className="flex items-center rounded shadow-lg w-full h-72 mb-8">
          <Loading isLoading={true}/>
        </div>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper 
      title="Cellar Favorites"
      className="grid grid-cols-12 gap-4 pb-16"
      readMoreLink="/articles?category=Cellar-Favorites&page=1&per_page=25&sort_by=date&sort_dir=desc"
      readMoreLabel="More Cellar Favorites"
    >
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(0)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(1)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(2)}
      />
    </SectionWrapper>
  )
}

export default CellarFavorites;
