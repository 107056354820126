import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useToast } from "../context/toastContext";
import {
  completeUserInfo,
  createStripeCoupon,
} from "../context/authContext/actions";
import { useAuth } from "../context/authContext";

export const useStripeSuccess = () => {
  const { dispatch } = useAuth();
  const router = useRouter();
  const toast = useToast(2000);
  const [isCompletingUser, setIsCompletingUser] = useState(true);
  const { session_id, gift_payment } = router.query;

  useEffect(() => {
    if (router.isReady){
      if (session_id && !gift_payment) {
        const completeSignUp = async () => {
          try {
            setIsCompletingUser(true);
            const response = await completeUserInfo(dispatch, session_id);
            if (response.status === 200)
              toast(
                "success",
                "Welcome. You have subscribed successfully.",
                null,
                () => {
                  router.replace("/", undefined, { shallow: true });
                }
              );
          } catch (error) {
            const errorMessage =
              error.response?.data?.message ||
              error.message ||
              error.data?.error ||
              "Unable to complete sign up";
            toast("error", errorMessage);
          } finally {
            setIsCompletingUser(false);
          }
        };
        completeSignUp();
      } else {
        setIsCompletingUser(false);
      }
    }

  }, [gift_payment, session_id, router.isReady]);

  useEffect(() => {
    if (router.isReady && session_id && gift_payment) {
      const createCoupon = async () => {
        const response = await createStripeCoupon(session_id);
        if (response.status === 201)
          toast("success", "Purchase completed successfully.", null, () => {
            router.replace("/", undefined, { shallow: true });
          });
        setIsCompletingUser(false);
      };
      createCoupon();
    }
  }, [gift_payment, router.isReady, session_id]);

  return {
    isCompletingUser,
  };
};
