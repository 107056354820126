import { Multimedia } from "../context/multimediaContext/reducer";

const URLRegex = new RegExp(/([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/);
const YouTubeIDRegex = new RegExp(/(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/)

export const getMediaURL = (media: Multimedia) => {
  if(!media) return;
  if(media.video_id) return "https://vimeo.com/" + media.video_id
  if(media.video_source) return media.video_source.match(URLRegex)[0]
}

export const getMediaImageURL = async (media: Multimedia) => {
  if(media.video_id) {
    return await getVimeoThumbnail(`https://vimeo.com/${media.video_id}`, media.thumbnail_url);
  }
  if(media.thumbnail_url) return media.thumbnail_url;
  
  const url = getMediaURL(media);
  const id = url ? url.match(YouTubeIDRegex) : false;

  if(id && id.length > 1) {
    let idWithoutParams = id[1];
    if (id[1].includes("?")) {
      idWithoutParams = id[1].split("?")[0];
    }
    return "https://img.youtube.com/vi/" + idWithoutParams + "/hqdefault.jpg"
  }
}

async function getVimeoThumbnail(videoUrl: string, thumbnail?: string): Promise<string | null> {
  const oEmbedUrl = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(videoUrl)}`;

  try {
    const response = await fetch(oEmbedUrl);
    if (!response.ok) {
      throw new Error(`Error fetching Vimeo thumbnail: ${response.statusText}`);
    }
    const data = await response.json();
    return data.thumbnail_url || thumbnail;
  } catch (error) {
    return thumbnail;
  }
}