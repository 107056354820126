import React, { useEffect, useState } from "react";
import SectionWrapper from "../../Section/SectionWrapper";
import MultimediaPreview from "../MultimediaPreview";
import { loadHomepageMultimedia, useMultimediaContext } from "../../../../context/multimediaContext";

const HomepageMultimedia = () => {
  const {
    state: { loading, homepageMultimedia },
    dispatch: multimediaDispatch,
  } = useMultimediaContext();
  const [firstLoad, setFirstLoad] = useState(true);
  
  useEffect(() => {
    if(!firstLoad) return;
    if(!loading && !homepageMultimedia) {
      loadHomepageMultimedia(multimediaDispatch);
      setFirstLoad(false);
    }
  }, [loading, multimediaDispatch, homepageMultimedia]);

  return (
    <SectionWrapper
      title="Multimedia"
      className="grid grid-cols-12 gap-4 pb-16"
      readMoreLink="/multimedia"
      readMoreLabel="More Multimedia"
    >
      {homepageMultimedia && homepageMultimedia.length > 3 && (
        <>
          <MultimediaPreview
            className="col-span-12 md:col-span-6 xl:col-span-3"
            media={homepageMultimedia[0]}
          />
          <MultimediaPreview
            className="col-span-12 md:col-span-6 xl:col-span-3"
            media={homepageMultimedia[1]}
          />
          <MultimediaPreview
            className="col-span-12 md:col-span-6 xl:col-span-3"
            media={homepageMultimedia[2]}
          />
          <MultimediaPreview
            className="col-span-12 md:col-span-6 xl:col-span-3"
            media={homepageMultimedia[3]}
          />
        </>
      )}
    </SectionWrapper>
  )

}

export default HomepageMultimedia;