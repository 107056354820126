import React from "react"
import SectionWrapper from "../../Section/SectionWrapper"
import FavoritePreview from "../../Favorites/FavoritePreview";

const VinousFavorites = ({ vinousFavorites }) => {
  return (
    <SectionWrapper 
      title="Vinous Favorites"
      className="grid md:grid-cols-3 gap-4 pb-16"
      readMoreLink="/vinous_favorites"
      readMoreLabel="More Vinous Favorites"
    >
      {
        vinousFavorites && vinousFavorites.slice(0, 3).map(fav => {
          const favorite = fav.house_favorite;
          return (
            <FavoritePreview 
              key={favorite.id}
              favorite={favorite}
            />
          )
        })
      }
    </SectionWrapper>
  )
}

export default VinousFavorites;