import React, { useState } from "react"
import SectionWrapper from "../../Section/SectionWrapper"
import ArticlePreview from "../Preview"
import { useArticlesContext, loadVinousTableArticles } from "../../../../context/articlesContext";
import Loading from '../../Loading';

const VinousTable = ({ vinousTableArticles }) => {
 

  const fetchArticleOrUndefined = (loc: number) => {
    let homepageArticles = vinousTableArticles.filter(article => article.homepage)
    let hiddenArticles = vinousTableArticles.filter(article => !article.homepage)
    return [...homepageArticles, ...hiddenArticles].slice(0,3)[loc]
  }

  if (!(vinousTableArticles && vinousTableArticles.length > 0)) {
    return (
      <SectionWrapper 
        title="Vinous Table"
      >
        <div className="flex items-center rounded shadow-lg w-full h-72 mb-8">
          <Loading isLoading={true}/>
        </div>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper 
      title="Vinous Table"
      className="grid grid-cols-12 gap-4 pb-16"
      readMoreLink="/articles?category=Vinous-Table&page=1&per_page=25&sort_by=date&sort_dir=desc"
      readMoreLabel="More Vinous Table"
    >
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(0)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(1)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(2)}
      />
    </SectionWrapper>
  )
}

export default VinousTable;
